@import 'styles/variables.scss';

.ApplicationError {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.ApplicationError__Message {
  max-width: 400px;
  margin: 12px 12px 64px;

  h1 {
    font-size: 30px;
    line-height: 46px;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
  }
}

.Button {
  margin: 10px 0;
  padding: 0 24px;

  min-width: 120px;
  font-size: 14px;
  line-height: 36px;

  border: solid 1px $gray-30;
  color: $text-color;
  border-radius: 6px;
  background-color: transparent;
  text-decoration: none;

  &[disabled] {
    background-color: $gray-20;
    color: $white;
    border-color: transparent;
    cursor: default;
  }
}

a.Button {
  display: inline-block;
  text-align: center;
}

.Button:hover {
  color: $gray-30;
  text-decoration: none;
}

.Button:active {
  color: $gray-50;
}

.Button--primary,
a.Button--primary {
  background-color: $button-primary;
  border-color: $button-primary;
  color: $white;
  text-decoration: none;
}

.Button--primary:hover {
  background-color: $button-primary-hover;
  color: $white;
}

.Button--primary:active {
  background-color: $button-primary-active;
  color: $white;
}

.Button--secondary {
  background-color: $white;
  border-color: $gray-50;
  color: $gray-50;
}

.Button--secondary:hover,
.Button--secondary:visited {
  background-color: $white;
  border-color: $gray-40;
  color: $gray-60;
}

.Button--tertiary {
  border-color: transparent;
  background-color: white;
  padding: 0;
}

.Button--danger {
  background-color: $button-danger;
  border-color: $button-danger;
  color: $white;
}

.Button--danger:hover {
  background-color: $button-danger-hover;
  color: $white;
}

.Button--danger:active {
  background-color: $button-danger-active;
  color: $white;
}

.Button--outline {
  border-color: $white;
  color: $white;
}

.Button--outline:hover {
  border-color: $white;
  color: $white;
}

.ConfirmationButton button {
  min-width: 140px;
}

.LinkButton {
  border: 0;
  background-color: transparent;
  font-size: 15px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.LinkButton:active {
  background-color: $stagepilot-orange;
  color: $text-color;
}

.LinkButton--light {
  color: $white;
  font-weight: bold;
}

.LinkButton:disabled {
  color: $gray-40;
}

.NavigationSection {
  border-left-style: solid;
  border-left-width: 6px;
  border-left-color: transparent;
}

.NavigationSection--highlighted {
  background-color: $gray-70;
}

.NavigationSection--teal.NavigationSection--highlighted,
.NavigationSection--teal:hover {
  border-left-color: $stagepilot-teal;
}

.NavigationSection--red.NavigationSection--highlighted,
.NavigationSection--red:hover {
  border-left-color: $stagepilot-red;
}

.NavigationSection--orange.NavigationSection--highlighted,
.NavigationSection--orange:hover {
  border-left-color: $stagepilot-orange;
}

.NavigationSection__Title,
.NavigationItem,
.NavigationItem:visited {
  padding: 0 0 0 12px;
  line-height: 36px;
  width: 100%;
  text-align: left;
  color: $white;
  text-decoration: none;
  display: block;
  border: 0;
}

.NavigationItem {
  cursor: pointer;
}

.NavigationItem--topLevel {
  border-left: solid 6px transparent;
}

.NavigationItem--topLevel.NavigationItem--active,
.NavigationItem--topLevel:hover {
  border-left-color: $stagepilot-green;
}

.NavigationSection .NavigationItem {
  padding-left: 1.8rem;
}

.NavigationItem:focus {
  color: $white;
}

.NavigationItem--active,
.NavigationItem--active:visited {
  background-color: $gray-60;
}

.NavigationItem:hover {
  background-color: $gray-60;
  text-decoration: none;
}

.NavigationItem--disabled {
  cursor: default;
  opacity: 0.5;
}

.NavigationItem--disabled:hover {
  background-color: inherit;
  opacity: 0.5;
}

.Form--fullWidth .ErrorMessage--validation {
  width: 100%;
  margin: 1rem 0;
  border: 0;
}

.Panel {
  background-color: $gray-0;
  border: 1px solid $gray-30;
  padding: 2rem 1rem;
}

.FieldSet {
  background-color: $gray-0;
  padding: 12px;
  margin: 12px 0;
}

.FieldSet .Input:last-child {
  margin-bottom: 0;
}

.Legend {
  font-size: 18px;
  color: $gray-70;
  font-weight: 400;
}

.BlankSlate {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 40%;
  text-align: center;
}

.BlankSlate__Title {
  max-width: 24rem;
  margin: 1rem 0;
}

.BlankSlate__Message {
  max-width: 24rem;
  line-height: 24px;
}

.BlankSlate__Image {
  width: 80px;
  height: 80px;
}

.Select {
  display: block;
  background-color: $white;
  width: 100%;
  line-height: 36px;
  margin: 0 0 18px;
}

.Select__Caption {
  display: inline-block;
  width: $caption-width;
}

.Select__Control {
  border: solid 1px $gray-20;
  border-radius: $control-border-radius;
  background-color: $white;
  line-height: 30px;
  height: 30px;
  padding: 0 6px;
}

.Select__Control:focus {
  border-color: $gray-40;
}

.Input {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 12px;

  &--tall {
    align-items: start;
  }

  &--fullwidth {
    flex-direction: column;
  }
}

.Input__Caption {
  display: flex;
  width: $caption-width;
  flex-shrink: 0;
  line-height: 24px;
}

.Input__Control {
  color: $text-color;
  width: 100%;
  border: solid 1px $gray-30;
  border-radius: $control-border-radius;
  line-height: 30px;
  padding: 0 6px;
}

.Input__Control--toggle {
  display: flex;
  flex-direction: row;
  padding: 0 6px;
}

.Input__Caption--toggle,
.Input__Control--toggle-text {
  line-height: 24px;
  max-width: 430px;
}

.Input__Caption--fullWidth {
  width: 100%;
  margin: 0 0 8px;
  font-weight: 500;
}

.Input__Control--fullWidth {
  width: 100%;
}

.Input__Control--mediumWidth {
  max-width: 280px;
}

.Input__Control--shortWidth {
  max-width: 140px;
}

.Input__Control--inline {
  margin-left: 0.5rem;
}

.Input__Control--no-label {
  margin-left: 130px;
}

.Input__Control--disabled {
  color: $gray-50;
}

.Input__Control--disabled .ToggleSwitch {
  opacity: 0.75;
}

.Input__Control::placeholder {
  color: $gray-40;
}

.Input__Control--date,
.Input__Control--time {
  width: 200px;
}

.Input__FileInputInlineCaption {
  display: none;
}

.Input__File--inline-caption .Input__Caption {
  display: none;
}

.Input__File--inline-caption .Input__FileInputInlineCaption {
  display: block;
}

.Input__FileArea {
  display: inline-flex;
  flex-direction: column;
  min-height: 188px;
  align-items: center;
  justify-content: center;
  background-color: $white;
  padding: 1rem;
}

.Input__FileInputPreview {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.Input__FileControl {
  display: none;
}

.Input__FileControl img {
  display: block;
  width: 180px;
  height: auto;
}

.Input--icon {
  .Input__Picker {
    margin: 0;
    padding: 6px 0;
    height: 100%;
    width: 100%;
    border: 0;
    background: transparent;
  }

  .Input__Icon {
    padding: 0 7px 0 2px;
    height: 22px;
  }

  .Input__Control {
    padding-right: 0;

    &--icon {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.Input__Hint,
.Input__Description {
  color: $gray-50;

  p {
    font-size: 0.75rem;
    line-height: 1.5;
    text-align: center;
  }
}

.Input--prefix,
.Input--suffix {
  position: relative;
  overflow: hidden;
}

.Input__Prefix {
  background: $gray-10;
  color: $gray-70;
  width: auto;
  border-right-width: 0;
  border-radius: 6px 0px 0px 6px;
  margin-right: -7px;
  padding-right: 5px;
  z-index: 1;

  + .Input__Control {
    padding-left: 10px;
    border-radius: 0px 6px 6px 0px;
  }
}

.Input__Suffix {
  line-height: 32px;
  padding: 0 6px;
  color: $gray-40;
  pointer-events: none;
  position: absolute;
  left: 132px;

  &--value {
    color: transparent;
  }
}

.Input__Description {
  width: $caption-width;
  position: absolute;
  margin: -22px 0 0;
}

.Input__Select {
  width: 100%;
  z-index: 2;
}

.react-datepicker {
  line-height: 18px;
  font-size: 11px;
}

.react-datepicker__header {
  background: transparent;
  border: 0;
  padding: 0;
}

.react-datepicker__current-month {
  background: $gray-10;
  color: $gray-70;
  padding: 7px 0;
  font-weight: normal;
  font-size: 14px;
}

.react-datepicker__navigation--previous {
  border-right-color: $gray-40;

  &:hover {
    border-right-color: $gray-30;
  }
}

.react-datepicker__navigation--next {
  border-left-color: $gray-40;

  &:hover {
    border-left-color: $gray-30;
  }
}

.react-datepicker__time-list-item {
  width: max-content;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day-names,
.react-datepicker__week {
  border-bottom: 1px solid $gray-20;
}

.react-datepicker__day,
.react-datepicker__day-name {
  color: $gray-70;
  margin: 7px 11px;
  width: 24px;
  line-height: 24px;
}

.react-datepicker__day-name,
.react-datepicker__day--outside-month {
  color: $gray-30;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: $teal-tint;
  color: $gray-70;
}

.react-datepicker__day--today {
  box-shadow: 0 0 0 1px $gray-70;
  font-weight: normal;
}

.react-datepicker__day:hover {
  background-color: $stagepilot-teal;
  color: $white;
}

.react-datepicker__day,
.react-datepicker__day:hover {
  border-radius: 100px;
}

.Input--optional .Input__Control {
  background-color: $gray-0;
}

.Input__Control:focus {
  border-color: $gray-40;
}

.DateInput,
.TimeInput {
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
}

.Page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  padding: 9px 30px 18px;
  background-color: $white;

  &__Title {
    margin-bottom: 0;
  }

  &__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 58px;
  }
}

.Page--dark {
  background-color: $gray-0;
}

.FillArea {
  margin-top: $header-height;
  padding: 30px;
}

.Sidebar {
  background-color: $gray-80;
  color: $white;

  width: 100%;
  height: 100%;
  padding: 91px 0 18px;
  min-height: 650px;
}

.Sidebar__BottomActions {
  position: absolute;
  bottom: 0;
  width: $sidebar-width;
  margin-bottom: 18px;
}

.Form {
  margin: 24px 0;
  position: relative;
}

.Logo {
  position: absolute;
  top: 21px;
  left: 18px;
  height: 40px;
}

.Logo--centered {
  left: calc(50% - 30px);
}

.FullModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;

  z-index: 100;

  background-color: $white;
}

.FullModal__Header {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  height: $header-height;
}

.FullModal__Close {
  position: absolute;
  top: 21px;
  right: 24px;
  border: 0;
  padding: 9px;
  border-radius: 20px;
  background-color: transparent;
}

.FullModal__Close:hover {
  background-color: $gray-10;
}

.FullModal__Close:active {
  background-color: $gray-50;
  color: $white;
}

.FullModal__Close:active g {
  stroke: $white;
}

.FullModal__Body {
  position: relative;
  padding: 30px;
  max-width: 640px;
  margin: auto;
}

.FullModal--fill-width .FullModal__Body {
  max-width: 100%;
}

.Alert {
  position: absolute;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.Alert__Close {
  position: absolute;
  right: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: $white;
  border: 0;
  background: none;
}

.Alert--info {
  background-color: $stagepilot-orange;
  color: $white;
}

.Alert--error {
  background-color: $stagepilot-red;
  color: $white;
}

.Alert__Message {
  padding: 1rem 0;
}

.AlertChange-enter {
  position: relative;
  top: -100px;
  transition: all 0.5s ease-in;
}

.AlertChange-enter.AlertChange-enter-active {
  position: relative;
  top: 0;
  transition: all 0.5s ease-in;
}

.AlertChange-leave {
  opacity: 1;
}

.AlertChange-leave.AlertChange-leave-active {
  opacity: 0.01;
  transition: opacity 0.5s ease-in;
}

.ToggleSwitch {
  margin: 3px 8px 0 0;
  padding: 1px;
  width: 32px;
  height: 18px;
  flex-shrink: 0;
  border: 1px solid $gray-30;
  border-radius: 12px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  background-color: $white;
}

.ToggleSwitch__Toggler {
  height: 14px;
  width: 14px;
  border-radius: 14px;
}

.ToggleSwitch__Toggler--on {
  margin-left: 14px;
  background-color: $stagepilot-teal;
}

.ToggleSwitch__Toggler--off {
  background-color: $gray-30;
}

.ToggleSwitch__Toggler--changed {
  transition: all 0.1s linear 0s;
}

.UploaderErrors {
  background-color: $gray-10;
  margin-bottom: 12px;
}

.UploaderErrors__Title {
  font-size: 14px;
  font-weight: bold;
  padding: 3px 9px 0;
  margin: 0;
}

.UploaderErrors__List {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  padding: 0 9px 12px;
  line-height: 20px;
}

.UploaderErrors__Actions {
  background-color: $white;
  text-align: center;

  button {
    margin: 12px 6px;
  }
}

.UploaderScheduleItemView {
  margin-top: 30px;
}

.UploaderScheduleItemView__Title {
  position: relative;
  height: 36px;
  background-color: transparent;
  transition: background-color 200ms linear;
}

.UploaderScheduleItemView__Title h1 {
  position: absolute;
  top: 2px;
}

.UploaderScheduleItemView__Toolbar {
  margin-top: 2rem;
  padding-bottom: 0.5rem;
  width: 100%;
  border-bottom: 1px solid $gray-30;
}

.UploaderScheduleItemView__ToolbarItem {
  margin-right: 36px;
  text-align: left;
}

.UploaderScheduleItemView__Post {
  display: inline-block;
  width: 200px;
  height: 200px;
  border-radius: $box-border-radius;
  background-color: $gray-20;
  line-height: 200px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
  background-size: cover;
}

.UploaderScheduleItemView__Post--selected {
  box-shadow: 0 0 1px 6px $stagepilot-teal;
  opacity: 0.8;
}

.UploaderScheduleItemView__DropTarget .DropTarget__Caption {
  position: fixed;
  top: $header-height;
  left: $sidebar-width;
  right: 0;
  bottom: 0;
}

.FilePicker {
  color: $stagepilot-teal;
}

.FilePicker:hover {
  text-decoration: underline;
}

.FilePicker:active {
  background-color: $stagepilot-orange;
  color: $text-color;
}

.FilePicker--button {
  display: inline-block;
  margin: 10px 0;
  padding: 0 24px;
  min-width: 120px;
  font-size: 14px;
  line-height: 36px;
  border-radius: 6px;
  color: $white;
  background-color: $button-primary;
}

.FilePicker--button:hover {
  background-color: $button-primary-hover;
  color: $white;
  text-decoration: none;
}

.FilePicker--button:active {
  background-color: $button-primary-active;
  color: $white;
}

.Separator {
  display: block;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
  margin-inline-start: auto;
  border: 0;
  border-top: 1px solid $gray-20;
}

.Table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 6px;

  th {
    text-align: left;
    line-height: 30px;
    padding: 0 12px;
    font-weight: normal;
    border-bottom: solid 2px $gray-30;
  }

  td {
    background-color: $gray-0;
    line-height: 36px;
    padding: 0 12px;
  }

  .right {
    text-align: right;
  }

  &--clickable tbody tr {
    cursor: pointer;
  }
}

.Modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
}

.Modal__Screen {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: $modal-tint;
}

.Modal__Window {
  min-width: 600px;
  margin: auto;
  background-color: $white;
  border-radius: 12px;
  border: solid 1px $gray-20;
  padding: 24px 30px;
}

.Modal__Header {
  position: relative;
}

.Modal__Header--dark {
  background-color: $stagepilot-red;
  color: $white;
  margin: -25px -31px 18px;
  padding: 24px;
  border-radius: 12px 12px 0 0;

  h1 {
    font-weight: normal;
    font-size: 14px;
    margin: 0;
    text-align: center;
  }

  .Modal__Close {
    display: none;
  }
}

.Modal__Close {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background: none;
  padding: 6px;
  border-radius: 20px;

  &:hover {
    background-color: $gray-10;
  }

  &:active {
    background-color: $gray-20;
    color: $white;
  }
}

.Modal__Body {
  margin-top: 30px;
}

.Modal__Buttons {
  text-align: right;
  margin: 36px 0 12px;
}

.Modal__Buttons button {
  margin: 0 9px;
}

.Modal__Buttons button:last-child {
  margin-right: 0;
}

.ErrorMessage {
  background-color: $stagepilot-red;
  color: $white;
}

.ErrorMessage--validation {
  margin: 1rem 0;
  padding: 12px;
}

.ErrorMessage--bar {
  line-height: 42px;
  padding: 0 12px;
  margin: 12px 0;
}

.ErrorMessage--app-error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ErrorMessage--app-error-msg {
  flex-grow: 1;
  text-align: center;
}

.SchedulePage .BlankSlate {
  margin: 12px 0;
}

.SchedulePage__ImportButtons {
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.SchedulePage__SyncButton {
  position: relative;
  left: -8px;
}

.Schedule__BandsintownLogo {
  width: 16px;
  height: 16px;
  margin-left: 12px;
}

.Schedule__Date {
  white-space: nowrap;
}

.Schedule__Time {
  white-space: nowrap;
}

.Schedule__ScheduleRow--hidden,
.UploaderOverview__Row--hidden,
.Schedule__Row--hidden {
  color: $gray-30;
}

.TicketsList__TicketsRow {
  cursor: pointer;
}

.FullPageForm {
  background-color: $white;
  border-radius: 6px;
  padding: 32px;
  max-width: 490px;
  margin: 0 auto;

  &__Hero {
    margin: 0 -32px 27px;
    padding: 0 32px;
    border-bottom: solid 3px $gray-0;

    h1 {
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 34px;
    }

    p {
      color: $stagepilot-teal;

      margin-top: -26px;
      margin-bottom: 34px;
    }
  }

  &__Title {
    margin-bottom: 0;
    font-weight: 500;
  }

  &__Subtitle {
    margin-bottom: 0px;
    font-weight: 500;
  }

  &__Description {
    margin-bottom: 25px;
  }

  &__Footer {
    margin-top: 12px;
    padding-top: 12px;
    border-top: solid 1px $gray-30;
    font-size: 10px;

    &--right {
      float: right;
    }
  }
}

.DashboardPage {
  padding-bottom: 2rem;
}

.DashboardPage__Charts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 12px;
}

.DateRangePicker {
  float: right;

  position: relative;

  .Button {
    margin-top: 0;
  }
}

.DateRangePicker__Inputs {
  display: flex;
  justify-content: space-between;

  margin-top: 22px;
}

.DateRangePicker__Label {
  display: block;
  margin-bottom: 9px;

  text-transform: uppercase;
  font-size: 10px;
  color: $gray-50;
  letter-spacing: 1px;
}

.DateRangePicker__Input {
  width: 150px;
  text-align: center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: $gray-0;
}

.DateRangePicker__Input:focus,
.DateRangePicker__Input--focused {
  border-color: $stagepilot-teal;
  outline: 0;
}
.DateRangePicker__Input--invalid,
.DateRangePicker__Input--invalid:focus {
  border-color: red;
  outline: 0;
}
.DateRangePicker__Error {
  width: 100%;
}
.DateRangePicker__Error_Text {
  display: flex;
  justify-content: center;
  background-color: red;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
}

.DateRangePicker__InputSeparator {
  margin: 28px 0 0;
}

.DateRangePicker__Popover {
  display: flex;
  flex-direction: column;
  position: absolute;

  width: 365px;

  top: 0;
  right: -10px;

  background: $white;
  z-index: 1;
  padding: 18px 18px 20px;
  border-radius: 6px;
  border: 1px solid $gray-30;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.15);

  .react-datepicker {
    border: 0;
  }
}

.DateRangePicker__Ranges {
  margin-top: 1rem;
}

.DateRangePicker__Buttons {
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  .Button {
    margin: 0;
    padding: 0;
    min-width: 100px;
    background: $gray-40;

    &:hover {
      background: $gray-30;
    }
  }
}

@keyframes orange-pulse {
  0% {
    background-color: $orange-tint;
  }

  50% {
    background-color: darken($orange-tint, 5%);
  }

  100% {
    background-color: $orange-tint;
  }
}

.UploaderProgress {
  position: relative;
  height: 36px;
  width: 100%;
}

.UploaderProgress__Bar,
.UploaderProgress__Fill {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
}

.UploaderProgress--active .UploaderProgress__Bar {
  background-color: $orange-tint;
  animation: orange-pulse 0.9s infinite;
}

.UploaderProgress--active .UploaderProgress__Fill {
  background-color: $stagepilot-orange;
}

.UploaderProgress--error .UploaderProgress__Bar {
  background-color: $red-tint;
}

.UploaderProgress--error .UploaderProgress__Fill {
  background-color: $stagepilot-red;
}

.UploaderProgress--complete .UploaderProgress__Bar {
  background-color: $green-tint;
}

.UploaderProgress--complete .UploaderProgress__Fill {
  background-color: $stagepilot-green;
}

.Link--container,
.Link--container:visited {
  color: inherit;
}

.EventSettingsForm__Locator {
  width: 64%;
  float: left;
}

.EventSettingsForm__Superuser {
  padding: 10px 15px 5px;

  border: 5px solid $stagepilot-red;
}

a.EventSettingsForm__Preview {
  max-width: 33%;
  line-height: 30px;
  border-radius: 100px;
  margin: 0 0 18px 20px;

  background: $gray-40;
  color: $white;

  &:hover {
    color: $white;
    text-decoration: none;
    background: $gray-30;
  }
}

.BandsintownModal_ConfirmationPanel {
  display: flex;
  height: 100px;
  margin-top: 21px;
  padding-top: 21px;
  border-top: solid 1px $gray-10;
}

.BandsintownModal_ConfirmedInfo {
  padding: 0 21px;
  line-height: 24px;
}

.BandsintownModal_Icon {
  width: 100px;
  height: 100px;
  background-color: $gray-10;
  border-radius: 6px;
}

.BandsintownModal_Loading,
.BandsintownModal_NotFound {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UploadScheduleCSVModal .ErrorMessage__Field {
  display: none;
}

.UploadScheduleCSVModal__Instructions {
  margin-bottom: 1.5rem;
}

.FlexSpacer {
  flex-grow: 1;
}
